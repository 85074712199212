
.login-error-msg{
  color: crimson;
}
.error-msg{
  color: crimson;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 7px;
}

.progress{
  background: #0d6efd;
}

.group[data-selected=true] .group-data-\[selected\=true\]\:opacity-100 {
  opacity: 1 !important;
}

.relative .text-foreground .select-none .text-large .transition-opacity {
  font-size: 13px !important;
}
.custom-check-wrpr span.text-foreground {
  font-size: 13px;
  margin-right: 9px;
  margin-top: 8px;
}

.loader{
  display: flex;
  justify-content: center;
  min-height: 220%;
}

.testspinner {
  width: 24px;
  position: relative;
  left: 0px;
  top: 2px;
  padding: 50px;
}

.testspinner .w-full {
  width: -10%;
  /* margin-right: 7px; */
}

.btn-send {
  align-items: center;
  display: inline-flex;
  /* padding-left: 24px !important; */
}

.testspinner .relative.flex.w-8.h-8 {
  position: relative;
  left: -7px;
  top: -3px;
  height: 20px;
  width: 20px;
}

.ant-btn
{
  background-color: #1677ff !important;
  
}

a.btn.btn-view.btn-round + .btn-round {
  margin-left: 6px;
}

body.disabled:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff24;
  z-index: 100;
}
.how-issue-closed .custom-control-input:checked ~ textarea {
  display: block !important;
  margin-left: -23px;
  width: 107%;
}


.logo-company{
  width: 40px;
  height: 40px;
  border-radius: 60px;
}

.loader-screen{
  margin-top: 275px;
}
.vesselnot{
  color: red;
  font-size: 10px;
}

.company-logo-custom {
  width: 45px;
  height: 45px;
}

.company-logo-custom img {
  width: 45px;
  height: 45px;
  border-radius: 30px;
}


.list-action-wrpr .vessel,
.list-action-wrpr .docholder,
.list-action-wrpr .fleet,
.list-action-wrpr .users,
.list-action-wrpr .issues,
.list-action-wrpr .reports
 {
  cursor: pointer;
}

footer {
  background: white !important;
}

.demonote{
  font-size: 10px;
}

.backtohome-btn-wrpr{
  cursor: pointer;
}

.required:after {
  content:" *";
  color: red;
}

.charts{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.responseMessagepointer{
  cursor: pointer;
}

.attachment-links a {
  min-width: 133px;
}



.react-tel-input .form-control {
  width: 100% !important;
  padding: 0 0 0 60px !important;
  height: 42px !important;
  border-radius: 10px !important;
  background: #eff0f1 !important; 
  border: 1px solid #e5e7eb !important;
  margin-top: 3px !important;
}
.paragraph {
  margin-bottom: 15px; /* You can adjust this value as needed for your desired spacing */
}

.collapse{
  visibility: visible !important;;
}

.navbar-brand .mr-auto{
  cursor: pointer;
}

.attachement-text{
  width: auto;
  word-break: break-all;
}

.accounthistory{
  cursor: pointer;
}


#efaq_accordion {
  margin-bottom: 80px;

}

#efaq_accordion .card-header {
  border: none;
  font-weight: 600;
  border-bottom:0px solid #2c64ae
}
#efaq_accordion .card-body {
  padding: 30px;
  font-size: 14px;
}
#efaq_accordion .card {
  border: none;
  margin-bottom: 20px;
  box-shadow: 0 0 50px rgb(0 0 0 / 15%);
  border-radius: 0px;
  border-bottom: 1px solid #337abb;
}

#efaq_accordion .card-header a {
  font-weight: 600;
  font-size: 14px;
  display:block;
  text-align:left;
}

#efaq_accordion .card-header a.btn.collapsed {
  position: relative;
}

#efaq_accordion .card-header a.btn.collapsed:after {
  content: "\e313";
  position: absolute;
  right: 0;
  font-family: icons-sm;
  font-size: 18px;
}

#efaq_accordion .card-header a[aria-expanded=true] {
  color: #3071b6;
position:relative;
}
#efaq_accordion .card-header a[aria-expanded=true]:after {
  content: "\e316";
  position: absolute;
  right: 0;
  font-family: icons-sm;
  font-size: 18px;
}
.fbicon{
  width: 33px;
}

.contactUs{
  cursor: pointer;
  white-space: nowrap;
}

ul.sidebar-menu.nav {
  overflow: hidden;
  overflow-y: auto !important;
  height: 85%;
}

ul.sidebar-menu.nav {
  overflow: hidden;
  overflow-y: auto !important;
  height: calc(100% - 115px);
}

::-webkit-scrollbar {
  width: 6px;
} 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
} 
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}

.icon-popout{
  cursor: pointer;
}

.input-group.custom-search .input-group-text {
    background: white;
    border-left: none;
    border-radius: 36px;
}

.input-group.custom-search .form-control {
    border-right: none;
    border-radius: 38px;
}

.input-group.custom-search {
    /* border-radius: 81px; */
    margin-right: 10px;
}

.table-section {
  width: 100%;
  overflow-x: auto;
}

.table-section .demo-table td {
  word-break: break-word;
}

.attachment-links div + div a {
  margin-left: 10px;
}

.attachment-links div + div p {
  margin-left: 10px;
}


.qr-text
{
  font-size: 30px;
}

.social{
  font-size: 20px;
}

.passwordshowhide{
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

.password-wrapper{
  position: relative;
  display: flex;
}