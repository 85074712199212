@font-face {
    font-family: 'icons-sm';
    src:  url('fonts/icons-sm.eot?ms3c7d');
    src:  url('fonts/icons-sm.eot?ms3c7d#iefix') format('embedded-opentype'),
      url('fonts/icons-sm.ttf?ms3c7d') format('truetype'),
      url('fonts/icons-sm.woff?ms3c7d') format('woff'),
      url('fonts/icons-sm.svg?ms3c7d#icons-sm') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons-sm' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body {
      overflow-x: hidden!important;
      font-family: 'Montserrat';
      -webkit-font-smoothing: antialiased;
       min-height: 100%; 
      /* background: #f1f2f7; */
       background: #fff; 
  }
  .left-side {
      background: none repeat scroll 0 0 #144a98;
      -webkit-box-shadow: inset -3px 0px 8px -4px rgb(0 0 0 / 10%);
      -moz-box-shadow: inset -3px 0px 8px -4px rgba(0, 0, 0, 0.1);
      box-shadow: inset -3px 0px 8px -4px rgb(0 0 0 / 7%);
      transition: all .2s linear;
      -o-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
  }
  .left-side {
      position: absolute;
      width: 257px;
      top: 0;
      left:0;
  }
  .right-side {
      background: #f0f2f5;
      background:#e6e8ea;
      margin-left: 257px;
      min-height:1000px !important;
  }
  .right-side, .left-side {
      min-height: 100%;
      display: block;
      transition: all .2s linear;
      -o-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
  }
  
  
  
  .navbar {padding: 15px 15px;}
  .navbar.sidenavbar .nav i {color: #144a98;font-size: 18px;margin-right: 10px; display:inline-flex;width: 30px;height: 30px;justify-content: center;align-items: center; background: #fff;border-radius: 50%; transition: all .2s linear;    -o-transition: all .2s linear;    -moz-transition: all .2s linear;    -webkit-transition: all .2s linear;}
  .navbar.sidenavbar .nav a {color: #fff;font-size: 15px; text-decoration:none; transition: all .2s linear;    -o-transition: all .2s linear;    -moz-transition: all .2s linear;    -webkit-transition: all .2s linear;}
  .nav i.icon-stack3 {font-size: 18px !important;}
  
  .nav i.icon-history {font-size: 17px !important;}
  .navbar.sidenavbar .nav .active a, .navbar.sidenavbar .nav .active i {color: #feac12; background:#073372;}
  .nav i.icon-user4 {font-size: 16px !important;}
  .nav i.icon-compose {font-size: 14px !important;}
  .sidenavbar .nav li a {display: flex;line-height: 31px;justify-content: flex-start;align-items: center;}
  .navbar.sidenavbar a:hover {color: #feac12;background:#0e3979;}
  .grade-cbe-wrpr {border-bottom: 1px solid rgb(255 255 255 / 11%);padding: 10px;display: flex;justify-content: center;align-items: center;}
  
  html {
      height: 100%;
  }
  body .login {
      background: url(../images/ship-bg-main.jpg);
      background-size:cover;
      height:100%;
  }
  .login-wrpr {
      display: flex;
      height: 100%;
  }    
  .login-wrpr .row {
      width: 100%;
      margin: 80px;
  }
  .logo {
      width: 222px;
  }
  .login-box {
      background: linear-gradient(201.51deg, rgba(255, 255, 255, 0.16) 0.63%, rgba(248, 248, 248, 0) 100%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
      min-height: 596px;
      border-radius: 12px;
      padding: 80px;
      border: 1px solid rgb(255 255 255 / 35%);
      backdrop-filter: blur(10px);
  }
  .btn-signin img {
      max-width: 20px !important;
      margin-left: 14px !important;
      display: inherit !important;
  }
  
  .btn-signin {
      width: 150px !important;
      border-radius: 50px !important;
      height: 45px !important;
      font-size: 20px !important;
      margin-top: 40px !important;
      background: linear-gradient(90.94deg, #0095FF -7.67%, #0058CD 110.55%) !important;
      border: none !important;	
  }
  .login-box .form-control:focus {
      background: transparent;
      box-shadow: none;
      border-bottom-color: white ;
      border-bottom-width: 2px;
      color:#fff;
  }
  .signin-btn-wrpr {
      margin-bottom: 40px !important;
  }
  
  
  .login-box input {
      background: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #fff;
      padding-left: 0;
      color: #fff;
      margin-bottom: 40px;
  }
  .login-box label{
      color:#fff;
      font-size: 20px;
      margin-top: 15px;
  }
  .backtohome-btn-wrpr a {
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      border-bottom: 1px solid #fff;
      padding-bottom: 6px;
      font-weight: 500;
  }
  .btn-signin:hover {
      box-shadow: 0 0 0 3px white;
  }
  
  
  /*table styles*/
  
  .table-wrpr{
      background: linear-gradient(180deg, #dbe4ee, #fff);
      padding: 7px;
      border-radius: 10px;
      margin-bottom: 30px;
      box-shadow: 0 10px 50px rgb(0 0 0 / 8%);
  }
  .table-wrpr table {
      overflow: hidden;
      border-radius: 6px;
      font-size: 13px;
      margin-bottom: 1px;
  }
  .table-wrpr thead {
      background: #0152ae;
      color: #fff;
      border-radius: 11px !important;
      border-collapse: separate;
      font-weight: 400;
  }
  .status {
      display: inline-flex;
      background: #f57840;
      color: #fff;
      padding: 1px 9px;
      border-radius: 4px;
      font-size: 12px;
  }
  .status.open {
      background: #2aa62c;
  }

  .status.inactive {
    background: #f57840;
}
  
  .status.closed {
      background: #b9cb65;
  }
  .status.accepted {
      background: #ffcd03;
      margin-right: 7px;
  }
  .accepted-and-by {
      display: flex;
      align-items: flex-start;
  }
  
  .accepted-by {
      font-size: 12px;
      line-height: 13px;
  }
  .btn-view,
  .btn-add{
      background: #0152ae;
      color: #fff;
      border-radius: 21px !important;
      font-size: 12px !important;
      padding: 3px 13px !important;
  }
  .btn-view:hover,
  .btn-add:hover {
      background: #0152ae;
      color: #fff;
  }
  
  .btn-view:focus:active,
  .btn-add:focus:active {
      background: #03a9f4;
      border-color: #03a9f4;
      color: #fff;
  }
  .btn-add {
      display: inline-flex;
      align-items: center;
  }
  
  .btn-add i {
      margin-right: 4px;
  }
  .addmodal .modal-header {
      padding: 10px;
      background: #ebebeb;
      border: none;
  }
  
  .addmodal .modal-header .modal-title {
      font-size: 17px;
  }
  
  .addmodal .modal-body {
      padding: 10px;
      background: rgb(128 128 128 / 9%);
      padding-top: 0;
  }
  
  .addmodal .modal-body label {
      font-size: 13px;
  }
  
  .addmodal .modal-body input,
  .addmodal .modal-body select {
      border-radius: 3px;
      border-bottom: 1px solid #dadada;
      font-size:12px;
  }
  .addmodal .modal-footer {
      padding: 10px;
      border: none;
  }
  
  .addmodal .modal-footer button {
      padding: 2px 19px;
      border-radius: 20px;
      background: #2196f3;
      border-color: #2196f3;
      font-size:13px;
      font-weight:600;
  }
  .addmodal .modal-header .btn-close {
      font-size: 10px;
  }
  /*table styles end*/
  
  .header {
      background: linear-gradient(45deg, #0089f5, #0164d7);
      background:#fff;
      height: 115px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 50px rgb(0 0 0 / 11%);
  }
  .logo-wrpr {
      margin-left: 15px;
      margin-right: 15px;
  }
  
  .page-title {
      color: #000;
      font-size: 18px;
  }
  .content-area {
      padding: 25px;
  }
  
  .user-profile-and-company-logo {
    background: #fff;
    min-width: 261px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 65px;
    margin-right: 20px;
    padding-left: 22px;
    padding-right: 12px;
    border-radius: 61px;
  }
  
  .user-profile-and-company-logo {}
  
  .company-logo {
      background: #0151ad;
      border-radius: 40px;
      padding: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      margin-left: 5px;
      height: 45px;
  }
  
  .company-logo img {
      max-width: 45px;
      /*position: relative;*/
      top: -3px;
      right: 0px;
  }
  
  .user-profile-name-avatar {
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
  }
  
  .user-profile-name-avatar span {
      margin-right: 8px;
  }
  
  .user-profile-name-avatar i {
      width: 45px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #0151ad;;
      border-radius: 520%;
      color: #fff;
      font-size: 24px;
  }
  .page-icon {
      color: #0151ad;
  }
  .title-and-companylogo {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
          margin-left: 21px;
  }
  .user-profile-name-avatar a:hover span {
      transform: translateY(-100px);
  }
  .user-profile-name-avatar a span {
      display: block;
      transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .user-profile-name-avatar a {
      color: #fff;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #0151ad;
      position: relative;
      text-decoration: none;
      flex-grow: 1;
      overflow: hidden;
      text-align: center;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
  }
  .user-profile-name-avatar a:after {
      content: attr(data-link-alt);
      display: inline;
      position: absolute;
      transition: top 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition-delay: 0s;
      transition-property: top;
      top: 100%;
      left: 0;
      right: 0;
      text-align: center;
  }
  
  .user-profile-name-avatar a:hover:after {
      top: 13px;
  }
  
  .user-profile-name-avatar em {
      font-style: normal;
  }
  .user-profile-name-avatar a:hover i:before {
      content: "\ea3e";
      position: relative;
      left: 2px;
      top: 2px;
      font-size: 20px;
  }
  
  .right-side, .left-side {
      min-height: 100%;
      display: block;
      transition: all .2s linear;
      -o-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
  }
  /* .left-side {
      background: linear-gradient(180deg, #0089f5, #0164d7);
      position: fixed;
      width: 320px;
      top: 0;
      left: 0;
  } */
  .left-side {
    background: linear-gradient(180deg, #0089f5, #0164d7);
    position: fixed;
    width: 320px;
    top: 0;
    left: 0;
    height: 100%;
}
  .right-side {
      background: rgb(238 239 239 / 65%);
      background: #eeefef;
      margin-left: 320px;
      min-height: 1000px !important;
  }
  .logo-cntnr {
      display: flex;
      height: 115px;
      justify-content: center;
      align-items: center;
      background: #1477d6;
  }
  a.sidebar-toggler.icon-menu1 {
      display: none;
  }
  ul.sidebar-menu.nav {
    display: flex;
    justify-content: flex-start;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  ul.sidebar-menu.nav a {
    align-items: flex-start;
    color: #fff;
    display: flex;
    flex-direction: row-reverse;
    font-size: 14px;
    justify-content: flex-end;
    line-height: 22px;
    padding-left: 20px;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  ul.sidebar-menu.nav li a span {
    align-items: center;
    background: transparent;
    border-radius: 50%;
    display: inline-flex;
    height: 26px;
    justify-content: center;
    left: -13px;
    position: relative;
    width: 33px;
  }
  
  ul.sidebar-menu.nav li.active a span {
      background: #fff;
      color: #0164d7;
  }
  ul.sidebar-menu.nav a span {
      display: flex;
      width: 29px;
  }
  ul.sidebar-menu.nav li {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
  }
  .sidebar-menu.nav a span {
      font-size: 17px;
  }
  ul.sidebar-menu.nav li a {
    border-radius: 4px;
    width: 100%;
  }
  ul.sidebar-menu.nav li a:hover {
      background: #0151ad;
  }
  
  ul.sidebar-menu.nav li.active a {
      /*background: #202259;*/
      box-shadow: 0 0 0 1px rgb(255 255 255 / 45%);
  }
  aside.left-side.side-off-canvas.sidebar-hidden {}
  
  .sidebar-hidden aside.left-side.side-off-canvas {
      left: -320px;
  }
  
  .sidebar-hidden aside.right-side {
      margin-left: 0;
  }
  
  .sidebar-hidden a.sidebar-toggler.icon-menu1 {
      display: flex;
      text-decoration: none;
      margin-right: 7px;
      color: #000;
      position: relative;
      top: -2px;
  }
  
  .sidebar-hidden .page-title {
      display: flex;
      align-items: center;
  }
  .sidebar-hidden.sidebar-active aside.left-side.side-off-canvas {
      left:0px;
  }
  
  .sidebar-hidden.sidebar-active aside.right-side a.sidebar-toggler.icon-menu1 {
      left: 269px;
      color: #fff;
  }
  
  .sidebar-hidden.sidebar-active a.sidebar-toggler.icon-menu1:before {
      content: "\e5cd";
  }
  .sub-head {
      background: #f1f4f8;
      height: 116px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .filter-search {
      display: flex;
      padding-left: 14px;
  }
  
  .filter-search select {
      margin-right: 8px;
      border-color: #e1e1e1;
      min-width: 130px;
  }
  
  .filter-search input {
      min-width: 170px;
      border-color: #e1e1e1;
  }
  .status-count{
      display:flex;
      margin-right:15px;
  }
  .status-count .status {
      width: 65px;
      height: 65px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 11px;
      padding: 1px 4px;
      margin: 0 3px;
  }
  .status-count .count {
      text-align: right;
      font-size: 35px;
      font-weight: 100;
  }
  .table-section .table tbody tr:last-child td {
      border: none;
  }
  /*table styles end*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* shipconrep admin user */
      .dashboard-card.card .card-body:before {
          content: "";
          position: absolute;
          width: 4px;
          height: 47px;
          background: #fff;
          left: -1px;
      }
      
      .dashboard-card.card i img {
          max-width: 40px;
      }
      
      .dashboard-cards {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
      }
  
  .dashboard-cards .card {
      width: 100%;
      margin:0 5px;
      overflow:hidden;
      border:none;
  }
  
      .shipconrep-admin-user .user-profile-and-company-logo {
          min-width: 191px;
      }
      
      .dashboard-card.card .card-body {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(180deg, #748bf5, #7074db);
      min-height: 120px;
      position:relative;
  }
  .dashboard-card.card.red .card-body {
      background: linear-gradient(180deg, #fc999c, #fd797c);
  }
  .dashboard-card.card.safron .card-body {
      background: linear-gradient(180deg, #55bdd0, #1690ba);
  }
  .dashboard-card.card.violet .card-body {
      background: linear-gradient(180deg, #9662b1, #7267d0);
  }
  
  .dashboard-card.card .card-body .card-label {
      font-size: 14px;
      color: #fff;
  }
  
  .dashboard-card.card .card-body .card-count {
      font-size: 22px;
      color: #fff;
      line-height: 23px;
  }
  
  .dashboard-card.card .card-body .card-icon {
      color: #fff;
      font-size: 30px;
  }
  
  
  
  .list-action-wrpr a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 49px;
      height: 49px;
      background: #4a6583;
      border-radius: 15px;
      color: #fff;
      font-size: 19px;
      text-decoration: none;
  }
  .roundicons-text a i {
          position: relative;
      }
      
      .list-action-wrpr a i:after {
          content: attr(data-count);
          position: absolute;
          font-size: 11px;
          background: #ea1b2c;
          color: #fff;
          min-width: 20px;
          height: 20px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          top: -9px;
          right: -6px;
          border-radius: 50%;
          font-family: 'Montserrat';
      }
      .list-action-wrpr a.no-count i:after{
          content: none;
      }
      .companies-list .list-items {
          background: #fff;
          padding: 20px;
          border-radius: 22px;
          box-shadow: 0 0 10px rgb(0 0 0 / 12%);
          margin-bottom: 20px;
          margin-left: 0px;
          margin-right: 0;
      }
  
  .vessels.icon-custom-image {
      background: url(../images/ship-icon.png) #4a6583 no-repeat center;
      background-size: 72%;
  }
  .docholder.icon-custom-image {
      background: url(../images/ship-icon.png) #06b781 no-repeat center;
      background-size: 72%;
  }
  .list-action-wrpr {
      display: flex;
  }
  
  
  
  /*Round Icons with Text anchors*/
  
  .roundicons-text a{
      background:none;
      flex-direction:column;
          width: auto;
      margin-left: 10px;
      margin-right: 10px;
      
  }
  .roundicons-text i.users {
      background: #ff9065;
  }
  .roundicons-text i.open-issues {
      background: #8bc94c;
  }
  .roundicons-text i.view {
      background: #eabb73;
  }
  
  
  .roundicons-text {
      justify-content: flex-end;
  }
  
  .roundicons-text i {
      border-radius: 50%;
      min-width:38px;
      min-height:38px;
      width: 38px;
      height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #4a6583;
      color: #fff;
      font-size: 19px;
      text-decoration: none;
  }
  .roundicons-text span {
      font-size: 12px;
      font-family: 'Montserrat';
      color: #000;
  }
  .page-rightside-action-column a.text-anchor {
      width: auto;
      border-radius: 30px;
      min-width: 162px;
      padding-right: 4px;
      font-weight: 500;
  }
  
  .page-rightside-action-column a.text-anchor span {
      font-size: 12px;
      white-space: nowrap;
  }
  
  .page-rightside-action-column a.text-anchor i {
      margin-right: 2px;
  }
  .page-rightside-action-column a.text-anchor.share {
      min-width: 90px;
  }
  .search-grp{
      transition: all .2s linear;
      -o-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
      border-radius: 21px;
  }
  .search-grp:hover {
      box-shadow: 0 0 0 2px #144a98;
  }
  .number-of {
      font-size: 12px;
  }
  .number-of.footer-number {
      margin-top: 37px;
  }
  .navbar.sidenavbar .nav i + i {
      background: transparent;
      color: #fff;
      margin-right: 0px;
  }
  .companies-list {font-size: 12px;}
  
  .companies-list .company-number {
      display: flex;
      flex-direction: column;
  }
  
  .companies-list .company-name .c-name, .companies-list .contactname, .companies-list .date {
      font-family: 'Montserrat';
      font-weight: 800;
      display: block;
  }
  
  .companies-list .company-name a,
  .companies-list  .contact-name-number a {
      color: #0085f2;
      font-weight: 600;
      text-decoration: none;
  }
  .w-5 {
      width: 5% !important;
  }
  .btn-addcompany {
      background: #0152ae;
      color: #fff;
      border-radius: 21px;
      font-size: 12px;
      padding: 2px 13px;
      padding-left: 0;
  }
  
  .btn-addcompany i {
      display: inline-flex;
      background: #fff;
      color: #0152ae;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      position: relative;
      left: 2px;
      margin-right: 7px;
  }
  .companies-heading h4 {
      font-size: 17px;
  }
  
  .companies-heading {
      margin-bottom: 14px;
  }
  .dashboard-cards .card:first-child {
      margin-left: 0;
  }
  
  .dashboard-cards .card:last-child {
      margin-right: 0;
  }
  
      .pagination {font-size: 13px; }
      .pagination li {margin: 0 4px;}
      .pagination li a {line-height: 9px;border-radius: 50% !important;color: #144a98;display: flex;justify-content: center;align-items: center;width: 38px;height: 38px;font-size: 13px;font-weight: 600;}
      .pagination-wrpr {display: flex;justify-content: center;margin-top: 50px;}
      .page-item.active .page-link {
          z-index: 1;
          color: #fff;
          background-color: #144a98;
          border-color: #144a98;
      }
  
  
  /*Round Icons with Text anchors end*/
  
  
/* shipconrep admin user */

.table-section td[data-bs-toggle="tooltip"] i {
    font-size: 16px;
    position: relative;
    top: 3px;
    color: #f57840;
}

.btn-view.btn-round {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    height: 28px;
    justify-content: center;
    max-width: 28px;
}
.dashboard-section-title {
    font-family: 'Montserrat';
    font-size: 25px;
    color: rgb(0 0 0 / 67%);
}
.dashboard-section-title .data-count {
    background: #5ac3d9;
    font-size: 13px;
    min-width: 25px;
    height: 25px;
    display: inline-flex;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
    top: -15px;
}
ul.breadcrumb {
  padding: 5px 0px;
  padding-bottom: 17px;
  list-style: none;
  border-bottom: 1px solid #d6d7d7;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
	padding:0 8px;
	color: black;
	content: "\e315";
	font-family: icons-sm;
	position: relative;
	top: 2px;
	font-size: 14px;
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
	color: rgb(0 0 0 / 67%);
	text-decoration: none;
    font-size: 13px;

}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
}


.issue-details-wrpr h5 {
    font-size: 22px;
    font-weight: 300;
    opacity: .68;
    margin: 19px 0;
}
.issue-details-wrpr .card {
    border-radius: 0px;
    border: blanchedalmond;
    box-shadow: 0 20px 30px rgb(0 0 0 / 8%);
    min-height: 310px;
    margin-top: 36px;
}
.issue-details-wrpr .card-body{
	padding:30px;
	font-size:13px;
}
.issue-details-wrpr .card-body label {
    font-weight: 600;
}

.issue-details-wrpr .card-body label + div {
    color: rgb(0 0 0 / 64%);
}
.detail-group , .issue-response-form{
    margin-bottom: 30px;
}
.issue-response-form input[type=text] {
    border: none;
    border-bottom: 1px solid #dadada;
    border-radius: 0px;
    padding-left: 0;
    font-size: 13px;
}

.issue-response-form .input-group-append button{
    border-radius: 50%;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0151ad;
    border: none;
}
.issue-details-wrpr .card-body .custom-checkbox label {
    display: block;
    font-size: 12px;
    margin-top: 9px;
    width: 26px;
    text-align: center;
}
.custom-control-label::before {
        position: absolute;
    top: 0px;
    left: 24px;
    display: block;
    width: 26px;
    height: 26px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}
.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
}
.custom-control-label::after {
    position: absolute;
    top: 0;
    left: 24px;
    display: block;
    width: 26px;
    height: 26px;
    content: "";
    background: 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    content:"\e5ca";
	font-family:icons-sm;
	font-size: 17px;
    color: #007ceb;
}
.custom-check-wrpr{
	display: inline-flex;
    position: relative
}
.issue-response-form textarea {
    border: none;
    border-bottom: 1px solid #dadada;
    border-radius: 0px;
    padding-left: 0;
    font-size: 13px;
    min-height:180px;
}
.prev-messages h6 {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    margin-top: 27px;
}

.prev-messages + .prev-messages {
    border-top: 1px solid #dadada;
}

.issue-response-form h5 {
    font-size: 17px;
    color: #0151ad;
    font-weight: 500;
}
.hidden-content-wrpr.active {
    display: block;
	transform:translateY(10px);
	
}

.hidden-content-wrpr {
    display: none;
	transition: all 12s linear;
    -o-transition: all 12s linear;
    -moz-transition: all 12s linear;
    -webkit-transition: all 12s linear;
	transform:translateY(-20px);
	
}
.hidden-content-wrpr {
    padding: 15px;
    border-radius: 3px;
    margin-top: 17px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 14%);
	
}
.response-checkbox-wrpr + .hidden-content-wrpr textarea {
    padding-left: 10px;
}
.issue-response-form input[type=text]:focus, .issue-response-form textarea:focus {
    border-bottom-color: #016cde;
    box-shadow: none;
}

.what-happend-content {
    height: 210px;
    overflow: hidden;
    overflow-y: auto;
}

/* width */
.what-happend-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.what-happend-content::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.what-happend-content::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.what-happend-content::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.label-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
}
.popupbtn-what-happend {
    font-size: 18px;
    text-decoration: none;
    position: relative;
    top: 3px;
	cursor:pointer;
}
#whathappend H4 {
    font-weight: 600;
}

#whathappend p {
    font-size: 14px;
}

#whathappend .modal-body {
    padding: 10px 29px;
}

#whathappend .modal-dialog {
    box-shadow: 0 20px 30px rgb(0 0 0 / 25%);
}
.custom-check .custom-check-wrpr .custom-control .custom-control-label:before {left: 0px;}

.custom-check .custom-check-wrpr .custom-control .custom-control-label:after {
    left: -5px;
}

.custom-check .custom-check-wrpr .custom-control {
    padding-left: 13px;
    margin-bottom: 14px;
    margin-right: 9px;
}
.history-table-wrpr td, .history-table-wrpr th {font-size: 12px;}

.blue-label{
	color:#0151ad;
	margin-bottom:15px;
	font-size: 1rem !important;
    font-weight: 300;
}
.sendresponse-form textarea {
    border: none;
    border-bottom: 1px solid #dadada;
    border-radius: 0px;
    padding-left: 0;
    font-size: 13px;
    min-height: 180px;
    margin-top: 15px;
}

.nav.nav-tabs a span {
    font-size: 10px;
	font-weight: 700;
}

.nav.nav-tabs a {
    line-height: 14px;
}
img{max-width:100%;}
.attachment-links a {
    width: 32%;
    display: inline-flex;
    background: border-box;
    height: 66px;
    background: #084298;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-decoration: none;
    color: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.attachment-links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
}

.additional-message-cntnr .custom-control.custom-checkbox {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.additional-message-cntnr .custom-control-input:checked ~ textarea  {
	display: block !important;
    margin-left: -23px;
    width: 107%;
}


.additional-message-cntnr .custom-check-wrpr {
    width: 100%;
    display: flex;
}

.additional-message-cntnr .custom-control.custom-checkbox label {
    width: 100%;
	margin-top: 6px;
	text-align: left;
    padding-left: 11px;
}

.additional-message-cntnr .custom-control.custom-checkbox label:before {
    left: 0px;
}

.additional-message-cntnr .custom-control.custom-checkbox label:after {
    left: 5px;
}

.additional-message-cntnr .custom-control.custom-checkbox input {
    opacity: 0;
}

.dateinput-wrpr {
    position: relative;
}

.dateinput-wrpr:before {
    content: "From";
    font-size: 13px;
    position: relative;
    left: 2px;
}
.logo-cntnr img {
    max-width: 230px;
}

.dateinput-wrpr.to:before {
    content: "To";
}

.dateinput-wrpr.from:before {
    content: "From";
}

.dateinput-wrpr.blank {
    display: block;
}

.dateinput-wrpr.blank button {
    display: block;
    line-height: 24px;
}

.dateinput-wrpr input {
    font-size: 14px;
}

.dateinput-wrpr {
    margin-right: 5px;
}

.dateinput-wrpr.blank:before {
    content: " ";
    display: block;
    height: 25px;
}
.dateinput-wrpr input {
    min-width: 170px;
}
.dateinput-wrpr.blank button i {
    margin-right: 7px;
    position: relative;
    top: 1px;
}

.dateinput-wrpr.blank button {
    background: #2550ac;
    border-color: #2550ac;
}

.btn-darkblue {
    background: #0152ae;
    color: #fff;
    border-radius: 21px;
    font-size: 13px;
    padding: 3px 13px;
    text-transform: uppercase;
    border-radius: 4px;
    line-height: 24px;
}
.btn-darkblue:focus:active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: #fff;
}
.btn-darkblue i {
    margin-right: 8px;
}

.h-unit-10.btn-add {
    height: auto;
}

/* App link and QR Codes */

.qr-wrpr {
    display: flex;
    background: #fff;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
}

.qr-wrpr .qr {
    max-width: 70px;
    margin-right: 10px;
}

.qr-wrpr img {
    max-width: 138px;
    height: auto !important;
}


.qr-code-wrpr {
    background: #123c5c;
	color:#fff;
}

.download-app{
	background: #123c5c;
	margin-bottom: 70px;
}

.download-app .qr-code {
    max-width: 140px;
    margin: 20px;
}

.download-app .col-md-6:nth-child(2) img {
    max-width: 143px;
}

.download-app h3 {
    margin-bottom: 40px;
}


/* App link and QR Codes end */
      
  
  @media screen and (max-width: 1000px) {
      a.sidebar-toggler.icon-menu1 {
          display: block;
      }
      .sidebar-active .sidebar-toggler.icon-menu1:before {
          content: '\e5cd';
      }
      .sidebar-active .sidebar-toggler.icon-menu1 {
          position: absolute;
          z-index: 101;
          color: #fff;
          text-decoration: none;
          left: 291px;
          top: 48px;
      }
      .page-title {
          display: flex;
          align-items: center;
      }
      .sidebar-active .left-side.side-off-canvas {
          left: 0px;
          z-index: 100;
          box-shadow: 10px 0 50px rgb(0 0 0 / 17%);
      }
      .page-title .sidebar-toggler {
          display: block;
          position: relative;
          top: -1px;
          color: #5c6c34;
          margin-right: 7px;
          text-decoration: none;
      }
  
  .left-side.side-off-canvas {
          transition: all .2s linear;
          -o-transition: all .2s linear;
          -moz-transition: all .2s linear;
          -webkit-transition: all .2s linear;
          left: -322px;
      }
      .right-side {
          transition: all .2s linear;
          -o-transition: all .2s linear;
          -moz-transition: all .2s linear;
          -webkit-transition: all .2s linear;
          margin-left: 0px;
      }
      
  }


  /* forgot password */

.login-box.forgotpass-box {
    padding: 50px;
}
.btn-signin.btn-resetpass {
    width: 242px !important;
    padding-left: 21px;
}

.btn-signin.btn-resetpass img {
    position: relative;
    top: -2px;
    right: -5px;
}
.login-box.forgotpass-box label {
    color: #000;
}

.login-box.forgotpass-box input {
    color: #000;
    border-bottom: 1px solid #333;
}
.login-box.forgotpass-box h4 {
    margin-bottom: 40px;
    font-weight: 600;
}
.login-box.forgotpass-box {
    background: linear-gradient(201.51deg, rgba(255, 255, 255, 0.16) 0.63%, rgba(248, 248, 248, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgb(255 255 255 / 87%));
}
.login-box.forgotpass-box .form-control:focus {
    background: transparent;
    box-shadow: none;
    border-bottom-color: black;
    border-bottom-width: 2px;
    color: #000;
}

.successfull-password-reset h4{
	margin-bottom:20px !important;
}
.successfull-password-reset.active {
	visibility:visible;
	transform:translate(0px, 0px);
}
.successfull-password-reset {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 50px;
    bottom: 50px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	visibility:hidden;
	transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
	transform:translate(0px, -20px);
}
.successfull-password-reset p {
    max-width: 60%;
    text-align: center;
    font-size: 18px;
}
.successfull-password-reset .icon-checkmark1 {
    font-size: 42px;
    margin-bottom: 40px;
    color: #2196F3;
}


/* forgot password end*/


/* FAQ, About us, Privacy */

.masthead.faq {
    background: linear-gradient(143deg, #1CB5E0 -14.55%, #002b8c 99.69%);
    height: 320px;
    min-height: unset;
    color: #fff;
	margin-bottom:0px;
}
.masthead.faq .bnr-top-pding {
    padding-top: 144px;
}
#faq_accordion {
    margin-bottom: 80px;
	margin-top:-100px;
}
#faq_accordion {
    margin-top: -85px;
    
}

#faq_accordion .card-header {
    border: none;
    font-weight: 600;
    border-bottom:0px solid #2c64ae
}
#faq_accordion .card-body {
    padding: 30px;
    font-size: 14px;
}
#faq_accordion .card {
    border: none;
    margin-bottom: 20px;
    box-shadow: 0 0 50px rgb(0 0 0 / 15%);
    border-radius: 0px;
    border-bottom: 1px solid #337abb;
}

#faq_accordion .card-header a {
    font-weight: 600;
    font-size: 14px;
    display:block;
    text-align:left;
}

#faq_accordion .card-header a.btn.collapsed {
    position: relative;
}

#faq_accordion .card-header a.btn.collapsed:after {
    content: "\e313";
    position: absolute;
    right: 0;
    font-family: icons-sm;
    font-size: 18px;
}

#faq_accordion .card-header a[aria-expanded=true] {
    color: #3071b6;
	position:relative;
}
#faq_accordion .card-header a[aria-expanded=true]:after {
    content: "\e316";
    position: absolute;
    right: 0;
    font-family: icons-sm;
    font-size: 18px;
}

.masthead.about-us {
    background: linear-gradient(143deg, #1CB5E0 -14.55%, #002b8c 99.69%);
    height: 490px;
    min-height: unset;
    color: #fff;
    margin-bottom: 0px;
}

.masthead.about-us {
    
    height: 490px;
    min-height: unset;
    color: #fff;
    margin-bottom: 0px;
    background: url(../images/aboutus-banner.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
}
.masthead.about-us:after{
	content: "";
    background: linear-gradient(143deg, #1cb5e07a -14.55%, #002b8cde 99.69%);
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.masthead.about-us h1{
	position:relative;
	z-index:10;
}
.masthead.about-us .bnr-top-pding {
    padding-top: 188px;
}
.aboutus-wrpr,
.privacy-wrpr {
    background: #fff;
    padding: 40px;
    box-shadow: 0 0 30px rgb(0 0 0 / 33%);
    top: -79px;
    position: relative;
}
.masthead.privacy-policy {
    background: linear-gradient(143deg, #1CB5E0 -14.55%, #002b8c 99.69%);
    height: 320px;
    min-height: unset;
    color: #fff;
    margin-bottom: 0px;
}
.privacy-wrpr p strong {
    font-weight: 600;
}

.privacy-wrpr p {
    font-size: 14px;
}

.privacy-wrpr h5 {
    margin-bottom: 20px;
	
}
.alpha{
	list-style-type:lower-alpha;
	margin: 19px;
}
.privacy-policy .bnr-top-pding {
    padding-top: 121px;
}
footer a{
	font-size:14px;
}
.navbar.scrolling.is-visible {
    background: #fff;
    box-shadow: 0 10px 20px rgb(0 0 0 / 3%);
}
/* FAQ, About us, Privacy End */

/* Chart */

.chart-wrpr, .chart-wrpr2 ,
.chart-wrpr3, .chart-wrpr4, .chart-wrpr5  {
    background: #fff;
    padding: 30px;
    box-shadow: 0 0 30px rgb(0 0 0 / 6%);
    border-radius: 10px;
	min-height:700px;
	overflow:hidden;
	margin-bottom:50px;
}
.chart-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(121deg, #243d6a, #324c98);
    color: #fff;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    min-height: 115px;
    min-height: 72px;
    margin-bottom: 10px;
}
.chart-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}
.chart-header h5 {
    font-size: 18px;
	margin-bottom:0px;
}
.chart-header p {
    font-size: 12px;
}


/* Chart end*/

/* company logo */

.company-logo-name-wrpr {
    display: flex;
    align-items: center;
}

.company-logo-name-wrpr .company-logo {
    margin-right: 10px;
}

.head-company-name {
    font-size: 20px;
    font-weight: 600;
}
.breadcrumb {
    font-size: 13px;
}

.breadcrumb a {
    text-decoration: none;
}

.qrwrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

/* company logo end*/


  @media screen and (max-width: 992px) {

/* App link and QR Codes start */
.qr-wrpr 
{
	margin-bottom: 10px;
}
	
.download-app h3 
{
    margin-top: 40px;
}

.download-app  .col-md-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}
	
/* App link and QR Codes end */
      

/* company logo  */
    .head-company-name {
		font-size: 13px;
	}
	.user-profile-and-company-logo{
		min-width: 211px;
	}



	.user-profile-name-avatar span{
		  font-size: 0px;
	}
	
	/* company logo end  */
      
      /* FAQ, About us, Privacy  */
	.privacy-wrpr, .aboutus-wrpr {
        margin-left: 15px;
        margin-right: 15px;
        width: auto;
    }

    .masthead.privacy-policy h1 + p {
        font-size: 12px;
        max-width: 80%;
        margin: 0 auto;
    }
    /* FAQ, About us, Privacy End */
      
      
      .login-wrpr .row {
          margin: 15px;
      }
      .login-box {
          min-height: 526px;
          padding: 40px;
      }
      .login-box input{
          margin-bottom:30px;
      }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media 
  only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px)  {
      
      
      
      
      
  }
  
    
    
    
  @media screen and (max-width: 600px) {
      
      
      
      
  }