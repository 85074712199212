@font-face {
    font-family: 'icons-sm';
    src:  url('fonts/icons-sm.eot?ms3c7d');
    src:  url('fonts/icons-sm.eot?ms3c7d#iefix') format('embedded-opentype'),
      url('fonts/icons-sm.ttf?ms3c7d') format('truetype'),
      url('fonts/icons-sm.woff?ms3c7d') format('woff'),
      url('fonts/icons-sm.svg?ms3c7d#icons-sm') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons-sm' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body {
      overflow-x: hidden!important;
      font-family: 'Montserrat';
      -webkit-font-smoothing: antialiased;
       min-height: 100%; 
      /* background: #f1f2f7; */
       background: #fff; 
  }
  
  
  
  .navbar {padding: 15px 15px;}
  .navbar.sidenavbar .nav i {color: #144a98;font-size: 18px;margin-right: 10px; display:inline-flex;width: 30px;height: 30px;justify-content: center;align-items: center; background: #fff;border-radius: 50%; transition: all .2s linear;    -o-transition: all .2s linear;    -moz-transition: all .2s linear;    -webkit-transition: all .2s linear;}
  .navbar.sidenavbar .nav a {color: #fff;font-size: 15px; text-decoration:none; transition: all .2s linear;    -o-transition: all .2s linear;    -moz-transition: all .2s linear;    -webkit-transition: all .2s linear;}
  .nav i.icon-stack3 {font-size: 18px !important;}
  
  .nav i.icon-history {font-size: 17px !important;}
  .navbar.sidenavbar .nav .active a, .navbar.sidenavbar .nav .active i {color: #feac12; background:#073372;}
  .nav i.icon-user4 {font-size: 16px !important;}
  .nav i.icon-compose {font-size: 14px !important;}
  .sidenavbar .nav li a {display: flex;line-height: 31px;justify-content: flex-start;align-items: center;}
  .navbar.sidenavbar a:hover {color: #feac12;background:#0e3979;}
  .grade-cbe-wrpr {border-bottom: 1px solid rgb(255 255 255 / 11%);padding: 10px;display: flex;justify-content: center;align-items: center;}
  
  
  .logo {
      max-width: 187px;
  }
  .form-wrpr {
      margin-top: 50px;
  }
  
  
  .informant-tabs button {
      border: none !important;
      border-radius: 30px !important;
      width: 35px;
      height: 35px;
      line-height: 30px;
      padding: 0px;
      background: #b1b1b1 !important;
      color: #fff !important;
      margin-right: 7px;
      margin-bottom: 20px !important;
      box-shadow: 0 0 0 4px rgb(0 0 0 / 8%);
      margin: 0 20px;
      position:relative;
      z-index:1;
  }
  .informant-tabs button.active {
      background: #3c85fd !important;
      box-shadow: 0 0 0 4px #3c85fd3d;
  }
  .informant-tabs {
      max-width: 635px;
      margin: 0 auto;
      position: relative;
      border:none;
  }
  
  .informant-tabs:before {
      content: "";
      position: absolute;
      display: flex;
      height: 3px;
      width: 100%;
      background: #b1b1b1;
      border-radius: 4px;
      top: 17px;
      z-index: 0;
  }
  .informant-tabs button:first-child {
      margin-left: 0;
  }
  
  .informant-tabs button:last-child {
      margin-right: 0;
  }
  .main-hding {
      text-align: center;
      margin-bottom: 30px;
      margin-top: 30px;
      font-size: 15px;
  }
  .informant-tab-pane label {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 10px;
  }
  
  .informant-tab-pane input,
  .informant-tab-pane select {
      border-radius: 21px;
  }
  .btn-informant {
      background: #404e60;
      border: none;
      min-height: 47px;
      border-radius: 40px;
  }
  
  .btn-informant i {
      background: #5283fb;
      width: 25px;
      height: 25px;
      line-height: 23px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      font-size: 22px;
      box-shadow: 0 0 0 2px;
      position: absolute;
      right: 12px;
      top: 10px;
  }
  
  .btn-informant {
      position: relative;
  }
  .donthave {
      background: linear-gradient(180deg, #3e93fd, #3064d6);
      color: #fff;
      padding: 15px;
      border-radius: 9px;
      margin-top: 17px;
      text-align: center;
  }
  .donthave .btn-clickhere {
      background: #fff;
      border-radius: 31px;
      margin-top: 15px;
      padding-left: 30px;
      padding-right: 30px;
  }
  .sub-title {
      position: relative;
  }
  
  .sub-title:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 50px;
      height: 3px;
      background: #5283fb;
      bottom: -7px;
      border-radius: 3px;
  }
  .agree-dontagree-wrpr {
      padding: 19px;
      overflow: hidden;
      overflow-y: auto;
      height: 500px;
  }
  .donthave .btn-clickhere:hover {
      color: #000;
  }
  
  .donthave .btn-clickhere:hover:focus {
      background: #fff;
  }
  
  
  /* width */
  .agree-dontagree-wrpr::-webkit-scrollbar {
    width: 10px;
   
  }
  
  /* Track */
  .agree-dontagree-wrpr::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .agree-dontagree-wrpr::-webkit-scrollbar-thumb {
    background: #888;
    border-radius:10px;
  }
  
  /* Handle on hover */
  .agree-dontagree-wrpr::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .btn-agree {
      background: #404e60;
      border: none;
      min-height: 37px;
      border-radius: 40px;
      min-width: 155px;
      text-align: left;
      position: relative;
      font-size: 14px;
      line-height: 25px;
  }
  
  .btn-agree i {
      background: #5283fb;
      display: inline-flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      position: absolute;
      right: 8px;
      top: 8px;
      box-shadow: 0 0 0 2px;
  }
  .btn-agree i.icon-close{
      background:#f4263d;
  }
  .btn-agree:hover {
      background: #303a4b;
  }
  
  .btn-agree:focus:active {
      background: #212937;
  }
  
  /* The customcheck */
  .customcheck {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .customcheck input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  }
  
  /* Create a custom checkbox */
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #eee;
      border-radius: 15px;
  }
  
  /* On mouse-over, add a grey background color */
  .customcheck:hover input ~ .checkmark {
      background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .customcheck input:checked ~ .checkmark {
      background-color: #5283fb;
      border-radius: 30px;
  }
  .form-check {
      position: absolute;
      right: 7px;
      top: 13px;
  }
  #category-acco .card-header {
      padding-left: 3px;
      border: none;
      background: none;
  }
  #category-acco .card {
      margin-bottom: 17px;
  }
  #category-acco .card-header a {
      font-weight: 600;
      position:relative;
  }
  
  #category-acco .card-body {
      padding-top: 0px !important;
  }
  #category-acco .card-header a:after {
      content: "\e313";
      font-family: 'icons-sm';
  }
  #category-acco .card-header a[aria-expanded=true]:after {
      content: "\e316";
      font-family: 'icons-sm';
  }
  
  
  
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }
  
  /* Show the checkmark when checked */
  .customcheck input:checked ~ .checkmark:after {
      display: block;
  }
  
  /* Style the checkmark/indicator */
  .customcheck .checkmark:after {
      left: 11px;
      top: 7px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  }
  .informant-tab-pane label.haveInfo {
      margin-bottom: 0 !important;
      display:block;
  }
  
  .informant-tab-pane label.haveInfo + small {
      font-size: 11px;
      position: relative;
      top: 2px;
      line-height: 13px !important;
      display: block;
      margin-bottom: 11px;
  }
  .smlfnt {
      font-size: 11px;
      margin-top: 2px;
  }
  .dropzone {
      border: 1px dashed #cfd4da;
      border-radius: 4px;
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px;
      width: 100%;
      max-width: 340px;
      flex-wrap: wrap;
  }
  .dz-default.dz-message {
      width: 100%;
  }
  .btn-informant.btn-informant-submit {
      background: #6fbc5c;
  }
  .btn-informant.btn-informant-submit i {
      background: #414d60;
  }
  .informant {
      background: linear-gradient(360deg, #ffffff, #3064d6);
      background-image: linear-gradient(to bottom, #051937, #062652, #0a346f, #12428d, #1d51ac, #4462bd, #6174ce, #7b87e0, #a1a3e8, #c3c0ef, #e2dff7, #ffffff);
      padding-bottom:100px;
  }	
  
  
  
  
  .btn-informant.btn-interested, .btn-informant.btn-not-interested {
      min-width: 249px;
      text-align: left;
  }
  
  .btn-informant.btn-interested {
      background: #284f8c;
  }
  
  .btn-informant.btn-not-interested {
      background: #c92b26;
  }
  
  .contact-details-msg {
      background: linear-gradient(45deg, #4780ed, #366cd1);
      padding: 24px;
      padding-left: 90px;
      padding-right: 90px;
      color: #fff;
      border-radius: 10px;
      margin-bottom: 19px;
      margin-left: -16px;
      margin-right: -16px;
      margin-top: -26px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }
  
  .contact-details-msg p:last-child {
      margin-bottom: 0;
      color: #ffc107;
      font-weight: 600;
  }
  
  
  .field-inline-icon {
      display: inline-flex;
      width: 100%;
      position: relative;
  }
  
  .field-inline-icon.phone:before,
  .field-inline-icon.whatsappphone:before,
  .field-inline-icon.language:before,
  .field-inline-icon.date:before  {
      content: "\e0cd";
      position: absolute;
      left: 11px;
      top: 7px;
      font-family: 'icons-sm';
      color: #496dd4;
  }
  .field-inline-icon.whatsappphone:before {
      content: "\eabd";
  }
  .field-inline-icon.language:before {
      content: "\ec72";
  }
  
  .field-inline-icon.date:before {
      content: "\eeca";
  }
  
  .field-inline-icon input {
      padding-left: 30px;
  }
  
  .thankyou {
      background: linear-gradient(45deg, #4ad8ea, #46c8e7);
      border: none;
      border-radius: 8px;
      margin-left: -16px;
      margin-right: -17px;
      margin-top: -21px;
      padding: 19px 140px;
      text-align:center;
      margin-bottom: -19px;
  }
  .refid {
      line-height: 38px;
      margin-bottom: 20px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
  }   
  
  
  .refid span {
      background: #fff;
      display: inline-flex;
      font-size: 19px;
      padding: 5px 30px;
      border-radius: 31px;
      letter-spacing: 1px;
      box-shadow: 0 0 7px rgb(0 0 0 / 20%);
  }
  .thankyou .main-hding {
      font-size: 30px;
      margin-top: 20px !important;
  }
  .thankyou a {
      color: #fff;
      font-weight: 600;
      text-decoration: none;
  }
  
  
  
  
  
  
  
  
  
  .reporting-checkbox-wror .form-check {
      right: unset;
      top: -2px;
      padding-left: 0px;
      margin-left: -35px;
  }
  
  
  /*home page*/
  .masthead {
      height: 1082px;
      min-height: 500px;
      background-image: url(../images/site/banner-pic.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 110px;
  }
  
  .employer-login {
      width: 173px;
      line-height: 19px;
      border-radius: 50px !important;
      border-width: 1px;
      border-style: solid;
      background: linear-gradient(#f3f5e9, #f4f5e9) padding-box,
                linear-gradient(to right, #0095FF, #0058CD) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
      text-align:center;
      font-size:16px;
      color:#000;
  }
  .bnr-top-pding {
      padding-top: 170px;
  }
  
  .masthead h1 {
      font-size: 34px;
      font-weight: 800;
  }
  .ship-stearing{
      width: 82px;
      height: 82px;
      background: linear-gradient(90deg, #008FFA -18.52%, #0060D4 116.67%);
      box-shadow: 4px 4px 10px 0px #00000040;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -41px;
  }
  .banner-footer {
      background: linear-gradient(180deg, #D7EBF0 4.29%, rgba(215, 235, 240, 0) 108.18%);
      border-radius: 20px;
      box-shadow: 0px 4px 44px 0px #00000026;
      position: relative;
      min-height: 214px;
      padding: 74px;
      margin-top: 460px;
  }
  img{
      max-width:100%;
  }
  
  .informant-cntnt {
      padding: 50px;
      position: relative;
  }
  
  .informant-cntnt:before {
      content: "";
      box-shadow: 4px 4px 50px 0px #0000001A;
      border-radius: 20px;
      display: flex;
      top: 0;
      bottom: 0;
      left: -140px;
      right: 0;
      position: absolute;
  }
  
  .section{
      padding:50px 0;
  }
  
  section h2 {
      color: #3575e4;
      font-weight: 600;
      margin-left: 101px;
      margin-bottom: 50px;
      position: relative;
      z-index: 0;
  }
  
  section h2:before {
          content:attr(data-title);
      position: absolute;
      font-size: 58px;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: rgb(56 128 238 / 18%);
      color: #fff;
      z-index: -1;
      left: -53px;
      top: -19px;
  }
  .section-two .informant-cntnt:before {
      left: 0;
      right: -180px;
  }
  .section-two h2 {margin-left: auto;margin-right: 0;width: 280px;}
  .blue-strip {
      background: linear-gradient(90deg, #008EF9 -5.42%, #0060D4 106.73%);
      color: #fff;
      padding-top: 50px;
      padding-bottom: 50px;
  }
  
  .blue-strip h3 {
      font-size: 18px;
      line-height: 23px;
  }
  .small-cards .card-body h4 {
      font-size: 18px;
      margin-top: 27px;
  }
  
  .small-cards .card-body {
      padding: 34px;
  }
  
  .small-cards .card {
      margin-bottom: 30px;
      box-shadow: 4px 4px 50px 0px #0000001A;
      border-radius: 20px;
      border: none;
  }
  .custom-pos-5 {
      position: relative;
      top: 81px;
  }
  
  section.section-three {
      padding: 80px 0;
  }
  
  section.section-three .col-md-5 img {
      border-radius: 20px;
  }
  
  .join-with-us {
      /*background: linear-gradient(90deg, #008EF9 -5.42%, #0060D4 106.73%);*/
      background:#3a86f3;
      margin-bottom: 60px;
      position:relative;
      min-height: 289px;
  }
  
  .join-with-us h2 {
      font-weight: 600;
      color: #fff;
  }
  
  .join-with-us h2 + p {
      color: #fff;
      font-size: 22px;
  }
  
  .btn-request-demo {
      background: #fff;
      border-radius: 50px;
      padding-left: 27px;
      padding-right: 27px;
      color: #0086F3;
      font-weight: 600;
      margin-top: 16px;
  }
  .join-with-us img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
  }
  .join-with-us .container {
      height: 100%;
      background: #3a86f3;
      min-height: 289px;
  }
  
  .join-with-us .container .col-md-5 {
      position: relative;
      z-index: 1;
      background: #3a86f3;
      min-height: 289px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
  }
  .footer {
      background: linear-gradient(90deg, #0095FF 0%, #0058CD 102.93%) !important;
      padding: 50px;
      padding-bottom:0px;
      color: #fff;
  }
  footer a{
      color:#fff;
  }
  
  footer h5 {
      font-size: 18px;
      margin-bottom: 20px;
  }
  
  footer a {
      font-size: 15px;
      text-decoration: none;
      margin-right: 26px;
  }
  
  footer a + a {
      border-left: 1px solid #fff;
      padding-left: 22px;
  }
  
  hr.border-white {
      margin-top: 50px;
  }
  footer .col-md-3 img {
      box-shadow: 0 0 0 5px white;
      border-radius: 19px;
  }
  .btn-workplace-issu {
      background: linear-gradient(90.94deg, #0095FF -7.67%, #0058CD 110.55%);
      width: 243px;
      line-height: 25px;
      border-radius: 41px;
      margin-top: 21px;
  }
  
  .is-hidden {
      opacity: 0;
      transition: all .2s linear;
      -o-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
      position: relative;
      z-index: -1;
      position: fixed;
  }
  .fixed-top.whiteNav.is-visible {
      background: #fff;
  }
  
  .desktoponly{
          display:block !important;
      }
      .phoneonly{
          display:none;
      }
  
  /*home page end */
  
  
  
  
  
  
  @media screen and (max-width: 992px) {
      section h2 {
          font-size: 32px;
          margin-left: 51px;
      }
      footer .pt-3.pb-5 {
          padding-bottom: 7px !important;
      }
      footer {padding: 10px;padding-top: 30px;}
  
      footer h5 {
          margin-top: 60px;
          margin-bottom: 10px;
      }
      .join-with-us .col-md-5 {
          padding-left: 0;
          padding-right: 0;
      }
      .join-with-us h2, .join-with-us p, .join-with-us .btn {
          margin-left: 15px;
          margin-right: 15px;
      }
      .join-with-us {
          padding-left: 0px;
          padding-right: 0;
      }
      .join-with-us .col-md-5 {
          margin-bottom: 40px;
      }
      .join-with-us img {
          position: static;
          margin-bottom: 30px;
          margin-top: 0px;
      }
      .small-cards {
          margin-top: 30px;
      }
      .custom-pos-5 {
          top: 0;
      }
      .informant-cntnt {
          padding: 30px;
      }
      .desktoponly{
          display:none !important;
      }
      .phoneonly{
          display:block;
      }
      .masthead {
          background-size: contain;
          background-color: #f1f9f6;
          height:756px;
      }
      .bnr-top-pding {
          padding-top: 110px;
      }
      .masthead{
          background-size: contain;
          background-position: 0 300px;
      }
      .banner-footer {
          padding: 24px;
          padding-top: 60px;
          margin-top: 230px;
          margin-left: -15px;
          margin-right: -15px;
          border-radius: 0px;
      }
      .employer-login {
          width: 100%;
          margin-top: 17px;
      }
      .masthead h1 {
          font-size: 27px;
      }
      
      
      
      /* navbar anim */
      #collapsingNavbar {
          order: 2 !important;
      }
      button.navbar-toggler {
          position: absolute;
          right: 0;
          border:none;
          top: 20px;
          
      }
          .navbar-light .navbar-toggler-icon {
          background-image: none;
      }
      .navbar-toggler-icon {
          display: block;
          position: absolute;
          top: 7px;
          right: 9px;
          width: 50px;
          height: 50px;
          cursor: pointer;
          z-index: 400;
          padding: 0;
      }
      .navbar-toggler-icon span {
          position: absolute;
          top: 50%;
          left: 10px;
          right: 10px;
          display: block;
          height: 4px;
          margin-top: -2px;
          background-color: #3c8bf7;
          font-size: 0px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-transition: background-color 0.2s;
          transition: background-color 0.2s;
      }
      .navbar-toggler-icon span:before, .navbar-toggler-icon span:after {
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background: #3c8bf7;
          content: '';
          -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
      }
      .navbar-toggler-icon span:before {
          -webkit-transform: translateY(-220%);
          transform: translateY(-220%);
      }
      .navbar-toggler-icon span:after {
          -webkit-transform: translateY(220%);
          transform: translateY(220%);
      }
      .navbar-toggler-icon.active span:before {
          -webkit-transform: translateY(0) rotate(45deg);
          transform: translateY(0) rotate(45deg);
      }
      .navbar-toggler-icon.active span:after {
          -webkit-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
      }
      span.navbar-toggler-icon.active span {
          background: transparent;
      }
      button.navbar-toggler:focus {
          outline: linen;
          box-shadow: none;
      }
      /* navbar anim ends */
      
      
      
      
      
      .informant-tabs button {
          margin: 0 4px;
          width: 30px;
          height: 30px;
      }
  
      .informant-tabs {
          max-width: 334px;
      }
  
      .informant-tabs:before {
          top: 15px;
      }
      #step1 .row .col,
      #step3 .row .col,
      #step5 .row .col	{
          width: 100%;
          min-width: 100%;
      }
      #step3 .row .col,
      #step5 .row .col{
          margin-top:15px;
      }
  
      #step2 .agreen-btn-wrpr {
          display: flex;
      }
  
      #step2 .agreen-btn-wrpr a {
          min-width: 149px;
          margin: 0 3px;
      }
  
  
      #step6 .col {
          padding: 0 3px;
      }
      #step7 .btn-informant {
          margin-bottom: 19px;
      }
  
      #step8 .col-md-6 {
          margin-top: 20px;
      }
  
      #step8 .agreen-btn-wrpr {
          display: flex;
      }
  
      #step8 .agreen-btn-wrpr a {
          margin: 0 10px;
          min-width: 143px;
      }
  
      #step8 .agreen-btn-wrpr a:first-child {
          margin-left: 0;
      }
  
      #step8 .agreen-btn-wrpr a:last-child {
          margin-right: 0;
      }
      .contact-details-msg {
          padding-left: 30px;
          padding-right: 30px;
      }
  
      #step9.thankyou {
          padding: 19px 30px !important;
      }
  
  }
  
  
  
  
  
    
    
    
  @media screen and (max-width: 600px) {
      
      
      
      
  }